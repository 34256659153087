<template>
    <div class="message-item flex">
        <div>
            <el-avatar :size="50" :src="item.avatar"></el-avatar>
        </div>
        <div class="message-item-content">
            <div class="message-item-title flex">
                <h3>{{ item.title }}</h3>
                <time>{{ item.date }}</time>
            </div>
            <div class="message-item-text">
                {{ item.content }}
            </div>
            <div class="message-item-reply-content">
                <p class="reply-name">{{ item.replyName }}</p>
                <p class="reply-text">{{ item.replyText }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'messageItem',
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
        }
    },
    methods: {

    },
}
</script>

<style scoped lang="less">
.message-item {
    padding-top: 30px;

    &:last-child {
        .message-item-content {
            border-bottom: 0;
        }
    }

    .message-item-content {
        margin-left: 15px;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(46, 48, 51, .1);

        h3 {
            font-size: 16px;
            color: #333;
            margin-right: 15px;
        }

        time {
            font-size: 14px;
            color: rgba(51, 51, 51, .6);
        }

        .message-item-text {
            font-size: 14px;
            color: #333;
            margin-top: 15px;
            line-height: 1.6;
        }

        .message-item-reply-content {
            color: rgba(51, 51, 51, .6);
            font-size: 14px;
            border-radius: 5px;
            padding: 10px;
            margin-top: 20px;
            background: #F9FAFC;

            .reply-name {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }
}
</style>