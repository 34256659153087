<template>
    <div>
        <headers />
        <div class="container">
            <div>
                <div class="userCenterWarp">
                    <div class="userCenterTitle">个人中心</div>
                    <el-row :gutter="30">
                        <el-col :span="4">
                            <sideNav />
                        </el-col>
                        <el-col :span="20" class="mt-30">
                            <el-breadcrumb separator-class="el-icon-arrow-right" class="user-breadcrumb">
                                <el-breadcrumb-item><strong>我的订单</strong></el-breadcrumb-item>
                            </el-breadcrumb>
                            <el-form ref="ruleForm" :model="ruleForm">
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <el-form-item>
                                            <el-input v-model="ruleForm.name" placeholder="请输入商品名称">
                                                <template #prefix>商品名称</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item>
                                            <el-input v-model="ruleForm.orderNumber" placeholder="请输入订单编号">
                                                <template #prefix>订单编号</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="10">
                                        <el-form-item>
                                            <el-select v-model="ruleForm.status" placeholder="请选择订单状态">
                                                <el-option label="区域一" value="shanghai"></el-option>
                                                <el-option label="区域二" value="beijing"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="14">
                                        <el-form-item>
                                            <el-date-picker v-model="ruleForm.date" type="datetimerange"
                                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-form-item>
                                    <el-button type="danger" @click="submitForm('ruleForm')">查询</el-button>
                                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>

                            <div>
                                <div class="table-header">
                                    <div class="prodInfo" style="text-indent: 10px;">商品信息</div>
                                    <div class="status">订单状态</div>
                                    <div class="contacts">联系人</div>
                                    <div class="tel">联系电话</div>
                                    <div class="addr">配送地址</div>
                                    <div class="operate">操作</div>
                                </div>
                                <prodItem @handleConfirm="handleConfirm" @handleDelete="handleDelete"
                                    @refundDelete="refundDelete" />
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>

        <el-dialog title="确定收货" :visible.sync="confirmDialogVisible" width="30%" center>
            <div class="text-center">
                <p style="font-size: 16px;color:#2E3033;margin-bottom: 10px;">您确定要收货订单吗？</p>
                <p style="font-size: 14px;color:rgba(46, 48, 51, .6)">收货后订单转入完成状态</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmDialogVisible = false">取 消</el-button>
                <el-button type="danger" @click="confirmDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog title="删除订单" :visible.sync="deleteDialogVisible" width="30%" center>
            <div class="text-center">
                <p style="font-size: 16px;color:#2E3033;margin-bottom: 10px;">您确定要收货订单吗？</p>
                <p style="font-size: 14px;color:rgba(46, 48, 51, .6)">收货后订单转入完成状态</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false">取 消</el-button>
                <el-button type="danger" @click="deleteDialogVisible = false">删 除</el-button>
            </span>
        </el-dialog>

        <el-dialog title="申请退款" :visible.sync="refundDialogVisible" width="50%" center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-form-item prop="reason">
                    <el-select v-model="ruleForm.reason" placeholder="请选择退款原因">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="content">
                    <el-input type="textarea" v-model="ruleForm.content" placeholder="请输入退款原因"
                        :autosize="{ minRows: 4, maxRows: 4 }" resize="none"></el-input>
                </el-form-item>
                <el-form-item prop="pic">
                    <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="ruleForm.pic" :src="ruleForm.pic" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="refundDialogVisible = false">取 消</el-button>
                <el-button type="danger" @click="refundDialogVisible = false">删 除</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import headers from '@/components/header.vue'
import sideNav from './components/sideNav.vue'
import prodItem from './components/prodItem.vue'
export default {
    name: 'profile',
    components: {
        headers,
        sideNav,
        prodItem
    },
    data () {
        return {
            confirmDialogVisible: false,
            deleteDialogVisible: false,
            refundDialogVisible: false,
            ruleForm: {
                reason: '',
                content: '',
                pic: ''
            },
            rules: {
                reason: [
                    { required: true, message: '请选择退款原因', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请输入退款原因', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        handleConfirm (data) {
            this.confirmDialogVisible = true
            console.log(data)
        },
        handleDelete (data) {
            this.deleteDialogVisible = true
            console.log(data)
        },
        refundDelete (data) {
            this.refundDialogVisible = true
            console.log(data)
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
        },

        handleAvatarSuccess (res, file) {
            this.ruleForm.pic = URL.createObjectURL(file.raw)
        },
        beforeAvatarUpload (file) {
            const isJPG = file.type === 'image/jpeg'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!')
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            }
            return isJPG && isLt2M
        },
    },
}
</script>

<style scoped lang="less">
/deep/.el-input--prefix {
    .el-input__inner {
        padding-left: 80px;
    }
}

.el-select,
.el-range-editor {
    width: 100%;
}

.el-date-editor::before {
    content: '下单时间';
    width: 80px;
    color: #C0C4CC;
}

.table-header {
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    background-color: #F9FAFC;

    div {
        display: inline-block;
        font-size: 14px;
        color: #2E3033;
    }
}

.avatar-uploader {
    /deep/.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
        }
    }

    .avatar {
        width: 60px;
        height: 60px;
        display: block;
    }
}
</style>