<template>
    <div>
        <headers />
        <div class="container">
            <div>
                <div class="userCenterWarp">
                    <div class="userCenterTitle">个人中心</div>
                    <el-row :gutter="30">
                        <el-col :span="4">
                            <sideNav />
                        </el-col>
                        <el-col :span="20" class="mt-30">
                            <div class="header flex space-between">
                                <div class="nav">
                                    <span :class="{ active: active === 0 }" @click="slected(0)">系统通知</span>
                                    <span :class="{ active: active === 1 }" @click="slected(1)">消息回复</span>
                                </div>
                                <div>
                                    <el-link type="danger" :underline="false" @click="handleConfirm">一键已读</el-link>
                                </div>
                            </div>

                            <messageItem v-for="item in list" :key="item.id" :item="item" />

                            <template v-if="list.length === 0">
                                <el-empty description="暂无数据"></el-empty>
                            </template>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headers from '@/components/header.vue'
import sideNav from './components/sideNav.vue'
import messageItem from './components/messageItem.vue'

import { getSysNoticeList, getMsgReplyList, msgReplyRead } from '@/api/apis'
export default {
    name: 'profile',
    components: {
        headers,
        sideNav,
        messageItem
    },
    data () {
        return {
            active: 0,
            list: [
                {
                    id: 1,
                    avatar: require('@/assets/img/3ea6beec64369c2642b92c6726f1epng.jpg'),
                    type: 0,
                    title: '系统通知',
                    content: '「有龙则灵」MADverse数字艺术展作品选拔赛火热进行中！MADverse摩境数字艺术空间携手站酷，以「龙」为主题， 面向全球设计师和数字艺术家，征集原创艺术作品和AIGC作品，开启站酷共创双赛道。寻找最「灵」的数字中华龙。',
                    replyName: '回复用户的昵称',
                    replyText: '「有龙则灵」MADverse数字艺术展作品选拔赛火热进行中！MADverse摩境数字艺术空间携手站酷，以「龙」为主题， 面向全球设计师和数字艺术家，征集原创艺术作品和AIGC作品，开启站酷共创双赛道。寻找最「灵」的数字中华龙。',
                    date: '今天05-18'
                }
            ]
        }
    },
    created () {
        //this.getNoticeList()
    },
    methods: {
        slected (e) {
            this.active = e
            console.log(e)
        },
        // 已读全部
        handleConfirm(){
            this.$confirm('确定已读全部通知吗？')
                .then(_ => {
                    handleReadAll()
                })
        },
        handleReadAll(){
            msgReplyRead().then(res => {
                if(res.code === 1) {
                    this.$message.success(res.msg);
                }
            })
        },
        // 系统消息列表
        getNoticeList() {
            getSysNoticeList().then(res => {
                if(res.code === 1) {
                    this.list = res.data.list
                }
            })
        },
        // 回复消息列表
        getDataReplyList() {
            getMsgReplyList().then(res => {
                if(res.code === 1) {
                    this.list = res.data.list
                }
            })
        }
    },
}
</script>

<style scoped lang="less">
.nav {
    span {
        display: inline-block;
        font-size: 18px;
        color: rgba(51, 51, 51, .6);
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        font-weight: 700;

        &+span {
            margin-left: 30px;
        }

        &.active {
            color: #333;
        }
    }
}
</style>