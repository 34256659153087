import request from '../utils/request';

const prefix = 'v1/user.user';

// 首页banner
export function getBanner() {
  return request({
    auth: false,
    url: 'common/banner',
    method: 'get',
  });
}

// 首页热词列表
export function getSearchList() {
  return request({
    auth: false,
    url: 'common/searchList',
    method: 'get',
  });
}

// 城市列表
export function getRegion() {
  return request({
    auth: false,
    url: 'common/region',
    method: 'get',
  });
}



/** 会员中心相关接口 str */


// 系统消息列表
export function getSysNoticeList() {
  return request({
    auth: true,
    url: prefix + '/sysNoticeList',
    method: 'get',
  });
}

/**
 * 消息一键已读
 * @body type 1:论坛 2:评价 3:公告
 * @returns 
 */
export function msgReplyRead(data) {
  return request({
    auth: true,
    url: prefix + '/msgReplyRead',
    method: 'post',
    data,
  });
}

/**
 * 消息回复列表
 * @body page 页码
 * @body pageSize 每页条数
 * @body type 1：视频 2：资料
 * @returns 
 */
export function getMsgReplyList(params) {
  return request({
    auth: true,
    url: prefix + '/msgReplyList',
    method: 'get',
    params,
  });
}

/**
 * 我的发布删除
 * @body id 消息id
 * @returns 
 */
export function delPublish(data) {
  return request({
    auth: true,
    url: prefix + '/delPublish',
    method: 'post',
    data,
  });
}

/**
 * 我的发布
 * @body page 页码
 * @body pageSize 每页条数
 * @returns 
 */
export function getMyPublish(params) {
  return request({
    auth: true,
    url: prefix + '/myPublish',
    method: 'get',
    params,
  });
}

/**
 * 修改密码
 * @body oldpassword 旧密码
 * @body newpassword 新密码
 * @body checkpassword 重复新密码
 * @returns 
 */
export function resetpwd(data) {
  return request({
    auth: true,
    url: '/user/resetpwd',
    method: 'post',
    data,
  });
}

/**
 * 用户信息修改
 * @body username 用户名
 * @body password 用户密码
 * @body avatar 用户头像
 * @returns 
 */
export function editInfo(data) {
  return request({
    auth: true,
    url: '/user/editInfo',
    method: 'post',
    data,
  });
}

/**
 * 入住供应商
 * @body realname 真实姓名
 * @body card_no 身份证号
 * @body mobile 手机号
 * @body intro 简介
 * @body business_cert 营业执照
 * @body card_cert 身份证人像面
 * @body card_reverse_cert 身份证国徽面
 * @returns 
 */
export function apply(data) {
  return request({
    auth: true,
    url: '/user.apply/apply',
    method: 'post',
    data,
  });
}
// 获取收货地址列表
export function getAddressList(data) {
  return request({
    auth: true,
    url: '/user.address/getList',
    method: 'get',
    data,
  });
}

// 获取收货地址列表
export function addressCreate(data) {
  return request({
    auth: true,
    url: '/user.address/addressCreate',
    method: 'post',
    data,
  });
}


/** 会员中心相关接口 end */

/**
 * 用户登录
 * account
 * password
 */
export function login(data) {
  return request({
    auth: false,
    url: '/user/login',
    method: 'post',
    data,
  });
}

/**
 * 用户注册
 * mobile
 * password
 * code
 */
export function register(data) {
  return request({
    auth: false,
    url: '/user/register',
    method: 'post',
    data,
  });
}