<template>
    <div class="prod-item">
        <div class="header flex space-between">
            <div class="flex align-items">
                <time>2024-05-20 20:30:32</time>
                <div class="ml-30">
                    <span>订单号：</span>20240520100001
                </div>
                <div class="flex align-items ml-30">
                    <img src="../../../assets/img/2.png" width="24px" height="24px">
                    <span class="ml-10">福建北峰科技股份有限公司</span>
                </div>
            </div>
            <div><i class="el-icon-delete" @click="$emit('handleDelete', '要删除的ID')"></i></div>
        </div>
        <div class="body flex">
            <div class="prodInfo flex">
                <div class="prodImg">
                    <img src="../../../assets/img/3ea6beec64369c2642b92c6726f1epng.jpg">
                </div>
                <div class="ml-10">
                    <div class="prodName">
                        <div class="text-hide">北斗信号弹TD10 北斗三号短报文便携式搜救终端</div>
                    </div>
                    <div class="prodPrice">单价：¥1999.00 数量：2</div>
                </div>
            </div>
            <div class="status flex align-items">
                待发货
            </div>
            <div class="contacts flex align-items">
                张三丰
            </div>
            <div class="tel flex align-items">
                15258745236
            </div>
            <div class="addr flex align-items">
                安徽省合肥市瑶海区明珠大厦1713
            </div>
            <div class="operate flex align-items">
                <el-link type="success" @click="$emit('handleConfirm', '这是来自子组件的数据')">确定收货</el-link>
                <el-link type="danger" @click="$emit('refundDelete', '申请退款')">申请退款</el-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'prodItem',
    data () {
        return {

        }
    },
    methods: {


    },
}
</script>

<style scoped lang="less">
.prodInfo {
    width: 36%;
}

.status {
    width: 8%;
    text-indent: 10px;
}

.contacts {
    width: 10%;
    text-indent: 10px;
}

.tel {
    width: 10%;
    text-indent: 10px;
}

.addr {
    width: calc(22% - 20px);
    padding: 0 10px;
}

.operate {
    width: 14%;
    text-indent: 10px;
}

.prod-item {
    margin: 15px 0;
    font-size: 14px;
    background: rgba(255, 255, 255, 0);
    border-radius: 5px 5px 5px 5px;
    border: 1px solid rgba(46, 48, 51, 0.1);

    .header {
        padding: 15px;
        color: #2E3033;
        background-color: #F9FAFC;

        span {
            color: #2E3033;
        }
    }

    .body {
        padding: 15px 0;

        .prodInfo {
            width: calc(36% - 20px);
            padding-left: 20px;
        }

        .prodImg {
            img {
                width: 60px;
                height: 60px;
                border-radius: 5px;
            }
        }

        .prodName {
            width: 260px;
            font-size: 14px;
            color: #2E3033;
            margin-bottom: 15px;
        }

        .prodPrice {
            font-size: 14px;
            color: hsla(216, 5%, 19%, 0.4);
        }
    }
}
</style>