<template>
    <div>
        <headers />
        <div class="container">
            <div>
                <div class="userCenterWarp">
                    <div class="userCenterTitle">供应商入驻</div>
                    <div class="header">
                        基本信息
                    </div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" style="width: 750px;">
                        <el-form-item label="真实姓名" prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入真实姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="idCard">
                            <el-input v-model="ruleForm.idCard" placeholder="请输入身份证号"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式" prop="phone">
                            <el-input v-model="ruleForm.phone" placeholder="请输入联系方式"></el-input>
                        </el-form-item>
                        <el-form-item label="基本介绍" prop="intro">
                            <el-input v-model="ruleForm.intro" placeholder="请输入基本介绍" type="textarea"
                                :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
                        </el-form-item>

                        <div class="header mt-60">
                            证明材料
                        </div>

                        <el-form-item label="身份证正反面" prop="imageUrlFront">
                            <el-row>
                                <el-col :span="12">
                                    <el-upload class="avatar-uploader idCardFront" action="#" :auto-upload="false"
                                        :show-file-list="false" :on-change="changePictureUploadIDFront">
                                        <img v-if="ruleForm.imageUrlFront" :src="ruleForm.imageUrlFront" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                                    </el-upload>
                                    <!-- <div class="el-upload__tip">身份证人像面</div> -->
                                </el-col>
                                <el-col :span="12">
                                    <el-upload class="avatar-uploader idCardReverse" action="#" :auto-upload="false"
                                        :show-file-list="false" :on-change="changePictureUploadIDReverse">
                                        <img v-if="ruleForm.imageUrlReverse" :src="ruleForm.imageUrlReverse"
                                            class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                                    </el-upload>
                                    <!-- <div class="el-upload__tip">身份证国徽面</div> -->
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item label="营业执照" prop="imageUrlBusinessLicense">
                            <el-upload class="avatar-uploader businessLicense" action="#" :auto-upload="false"
                                :show-file-list="false" :on-change="changePictureUploadBusinessLicense">
                                <img v-if="ruleForm.imageUrlBusinessLicense" :src="ruleForm.imageUrlBusinessLicense"
                                    class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon" />
                            </el-upload>
                            <!-- <div class="el-upload__tip">营业执照</div> -->
                        </el-form-item>

                        <el-form-item>
                            <el-button type="danger" class="custom-btn" @click="submitForm('ruleForm')">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headers from '@/components/header.vue'
export default {
    name: "settled",
    components: {
        headers,

    },
    data () {
        return {
            ruleForm: {
                name: '',
                idCard: '',
                phone: '',
                intro: '',
                imageUrlFront: '',
                imageUrlReverse: '',
                imageUrlBusinessLicense: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入真实姓名', trigger: 'blur' }
                ],
                idCard: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' }
                ],
                intro: [
                    { required: true, message: '请输入基本介绍', trigger: 'blur' }
                ],
                imageUrlFront: [
                    { required: true, message: '请上传身份证人像面', trigger: 'blur' }
                ],
                imageUrlReverse: [
                    { required: true, message: '请上传身份证国徽面', trigger: 'blur' }
                ],
                imageUrlBusinessLicense: [
                    { required: true, message: '请上传营业执照', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        // 文件发生变化时
        changePictureUploadIDFront (file, fileList) {
            this.ruleForm.imageUrlFront = URL.createObjectURL(file.raw)
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.raw.size / 1024 / 1024 < 2

            if (file.raw) {
                if (isJPG || isPNG) {
                    const fileFormData = new FormData()
                    fileFormData.append('files', file.raw)
                    addIdCard(fileFormData).then(res => { //调用上传接口
                        if (res) {
                            this.ruleForm.imageUrlFront = URL.createObjectURL(file.raw)//接口调用成功后赋值src
                            this.$message({
                                message: '恭喜你，上传成功！',
                                type: 'success'
                            })
                        }
                    })
                    this.ruleForm.imageUrlFront = URL.createObjectURL(file.raw)
                } else {
                    this.$message.error('上传图片只能是 JPG或PNG 格式!')
                }
            }

            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!')
            }
            // 返回图是jpg或png格式并且大小不超过2MB
            return (isJPG || isPNG) && isLt2M
        },
        changePictureUploadIDReverse (file, fileList) {
            this.ruleForm.imageUrlReverse = URL.createObjectURL(file.raw)
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.raw.size / 1024 / 1024 < 2

            if (file.raw) {
                if (isJPG || isPNG) {
                    const fileFormData = new FormData()
                    fileFormData.append('files', file.raw)
                    addIdCard(fileFormData).then(res => { //调用上传接口
                        if (res) {
                            this.ruleForm.imageUrlReverse = URL.createObjectURL(file.raw)//接口调用成功后赋值src
                            this.$message({
                                message: '恭喜你，上传成功！',
                                type: 'success'
                            })
                        }
                    })
                    this.ruleForm.imageUrlReverse = URL.createObjectURL(file.raw)
                } else {
                    this.$message.error('上传图片只能是 JPG或PNG 格式!')
                }
            }

            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!')
            }
            // 返回图是jpg或png格式并且大小不超过2MB
            return (isJPG || isPNG) && isLt2M
        },
        changePictureUploadBusinessLicense (file, fileList) {
            this.ruleForm.imageUrlBusinessLicense = URL.createObjectURL(file.raw)
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            const isLt2M = file.raw.size / 1024 / 1024 < 2

            if (file.raw) {
                if (isJPG || isPNG) {
                    const fileFormData = new FormData()
                    fileFormData.append('files', file.raw)
                    addIdCard(fileFormData).then(res => { //调用上传接口
                        if (res) {
                            this.ruleForm.imageUrlBusinessLicense = URL.createObjectURL(file.raw)//接口调用成功后赋值src
                            this.$message({
                                message: '恭喜你，上传成功！',
                                type: 'success'
                            })
                        }
                    })
                    this.ruleForm.imageUrlBusinessLicense = URL.createObjectURL(file.raw)
                } else {
                    this.$message.error('上传图片只能是 JPG或PNG 格式!')
                }
            }

            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!')
            }
            // 返回图是jpg或png格式并且大小不超过2MB
            return (isJPG || isPNG) && isLt2M
        },

        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm (formName) {
            this.$refs[formName].resetFields()
        }
    }
}
</script>

<style lang="less" scoped>
.header {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 30px;
}

.avatar-uploader {
    &.idCardFront {
        /deep/.el-upload {
            background-image: url(../../assets/img/idCardFront.png);
        }
    }

    &.idCardReverse {
        /deep/.el-upload {
            background-image: url(../../assets/img/idCardReverse.png);
        }
    }

    &.businessLicense {
        /deep/.el-upload {
            background-image: url(../../assets/img/businessLicense.png);
        }
    }

    /deep/.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 12px;
        cursor: pointer;
        position: relative;
        overflow: hidden;



        &:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 255px;
            height: 160px;
            line-height: 160px;
            text-align: center;
            opacity: 0;
        }
    }

    .avatar {
        width: 255px;
        height: 160px;
        display: block;
    }
}
</style>